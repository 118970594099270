import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "md-tooltip-wrapper",
    onMouseenter: _cache[0] || (_cache[0] = ($event: any) => (_ctx.show = true)),
    onMouseleave: _cache[1] || (_cache[1] = ($event: any) => (_ctx.show = false))
  }, [
    _renderSlot(_ctx.$slots, "default", {}, undefined, true),
    _createVNode(_Transition, { name: "tooltip" }, {
      default: _withCtx(() => [
        (_ctx.show)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: _normalizeClass(["md-tooltip", [ _ctx.position ]])
            }, _toDisplayString(_ctx.text), 3))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ], 32))
}