
import { defineComponent } from "vue";
import { TopNav } from "@/components/cc/TopNav";
import { MdButton } from "./md/MdButton";

export default defineComponent({
  name: "OverviewTopNav",
  components: {
    TopNav,
    MdButton,
  }
});
