
import { defineComponent, PropType } from "vue";
import { MdCard } from "@/components/md/MdCard";
import { MdInputField } from "@/components/md/MdInput";
import { MdButton } from "@/components/md/MdButton";
import { MdTooltip } from "@/components/md/MdTooltip";
import { IUniPickerCategory } from "@/types";
import PickerMixin from "@/components/bot/PickerMixin";

// TODO add search

export default defineComponent({
  name: "UniPicker",
  mixins: [PickerMixin],
  components: {
    MdCard,
    MdInputField,
    MdButton,
    MdTooltip
  },
  props: {
    data: {
      type: Object as PropType<{[key: string]: IUniPickerCategory}>,
      required: true
    },
    typeName: String,
  },
  data() {
    return {
      selectedCategory: null as string | null,
      selectedItem: null as string | null,
      posX: 0,
      posY: 0
    }
  },
  watch: {
    modelValue: {
      immediate: true,
      handler(value: string) {
        if(value && value.trim().length > 0) {
          Object.keys(this.data).forEach(category => {
            this.data[category].items.forEach(item => {
              if(item.value == value) {
                this.selectedCategory = category;
                this.selectedItem = item.value;
              }
            });
          });
        } else {
          this.selectedCategory = Object.keys(this.data)[0];
        }
      }
    },
    anchor: {
      immediate: true,
      deep: true,
      handler(anchor: HTMLElement | null) {
        if(!anchor) return;
        const targetPos = anchor.getBoundingClientRect();
        const clientPos = anchor.getClientRects();
        const pickerHeight = 350;
        this.posX = targetPos.right + 1;
        this.posY = targetPos.top - pickerHeight / 2;
      }
    }
  },
  methods: {
    switchCategory(category: string) {
      this.selectedCategory = category;
    },
    selectItem(value: string) {
      this.selectedItem = value;
      this.$emit("update:modelValue", value);
      this.$emit("select", {
        category: this.selectedCategory,
        value: value
      });
    }
  },
});
