
import { defineComponent } from "vue";
import { TopNav } from "@/components/cc/TopNav";
import { MdSelect } from "@/components/md/MdInput";
import { IMenuItem } from "@/components/md/MdMenu";
import { MdButton } from "@/components/md/MdButton";
import { useStore } from "@/store";
import { BotStore } from "@/store/constants";
import { IModel } from "@chatcaptain/types/chatbot";

export default defineComponent({
  name: "BotTopNav",
  components: {
    TopNav,
    MdSelect,
    MdButton,
  },
  setup() {
    const store = useStore();
    store.dispatch(BotStore.Actions.LOAD_MODELS);
    store.dispatch(BotStore.Actions.LOAD_LANGUAGES);
    return { store };
  },
  data() {
    return {
      language: "DE"
    }
  },
  watch: {
    language(val: string) {
      this.store.commit(BotStore.Mutations.SET_LANGUAGE, val);
    }
  },
  computed: {
    languages(): string[] {
      return this.store.getters[BotStore.Getters.GET_LANGUAGES];
    },
    items(): IMenuItem[] {
      const items: IMenuItem[] = [];
      this.languages.forEach(lang => {
        items.push({
          label: lang.toUpperCase(),
          value: lang,
          icon: "language"
        });
      });
      return items;
    },
    models(): IModel[] {
      return this.store.getters[BotStore.Getters.GET_MODELS];
    },
    trainingRequired(): boolean {
      return this.models.some(model => model.state === "untrained");
    },
    untrainedModelLanguagesLabel(): string {
      return this.models.filter(model => model.state === "untrained").map(model => this.getLanguageName(model.language)).join(", ");
    }
  },
  methods: {
    getLanguageName(language: string): string {
      return this.store.getters[BotStore.Getters.GET_LANGUAGE_NAME](language);
    },
    publish() {
      this.$router.push({
        name: "BotVersions",
        query: {
          publish: 1
        }
      });
    }
  },
});
