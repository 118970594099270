
import { defineComponent } from "vue";
import MdInputMixin from "./MdInputMixin";
import { uuid } from "@/utils/MdUuid";
import { useStore } from "@/store";
import { BotStore, RootStore } from "@/store/constants";
import { IVariablePickerPayload } from "@/store/types";

export default defineComponent({
  name: "MdVariableInputField",
  mixins: [MdInputMixin],
  props: {
    id: {
      type: String,
      default: () => uuid("variable-input-field"),
    }
  },
  setup() {
    return {
      store: useStore()
    }
  },
  data() {
    return {
      showPicker: false,
      posX: 0,
      posY: 0,
      variable: this.modelValue
    }
  },
  watch: {
    modelValue: {
      immediate: true,
      handler(value: string) {
        if(!value) return;
        this.variable = value;
      }
    },
    variable(value: string) {
      console.log("triger");
      this.$emit("update:modelValue", value);
    }
  },
  computed: {
    element(): HTMLInputElement {
      return this.$refs.input as HTMLInputElement;
    },
    target(): HTMLInputElement {
      return this.$refs.target as HTMLInputElement;
    },
    name(): string {
      if(!this.modelValue || this.modelValue.toString().length == 0) return "";
      return this.store.getters[BotStore.Getters.GET_PICKER_VARIABLE](this.modelValue as string)?.label || "";
    }
  },
  methods: {
    onClick(event: PointerEvent) {
      this.store.commit(RootStore.Mutations.OPEN_VARIABLE_PICKER, {
        anchor: event.target,
        target: this.target,
        variable: this.modelValue
      } as IVariablePickerPayload);
    },
    onChange(event: InputEvent) {
      this.variable = (event.target as HTMLInputElement).value;
      this.$emit("update:modelValue", this.variable);
    }
  },
});
