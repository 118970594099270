import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4fa2ab74"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "md-navigation-entry" }
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_md_navigation_divider = _resolveComponent("md-navigation-divider")!
  const _component_md_navigation_headline = _resolveComponent("md-navigation-headline")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.item.divider)
      ? (_openBlock(), _createBlock(_component_md_navigation_divider, { key: 0 }))
      : (_ctx.item.headline)
        ? (_openBlock(), _createBlock(_component_md_navigation_headline, { key: 1 }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.item.headline), 1)
            ]),
            _: 1
          }))
        : (_ctx.item.href)
          ? (_openBlock(), _createBlock(_component_router_link, {
              key: 2,
              to: _ctx.item.href
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", {
                  class: _normalizeClass(["md-navigation-item", [ _ctx.item.active ? 'active' : '' ]])
                }, [
                  (_ctx.item.icon)
                    ? (_openBlock(), _createElementBlock("i", {
                        key: 0,
                        class: _normalizeClass([_ctx.item.active ? 'material-icons-round' : 'material-icons-outlined'])
                      }, _toDisplayString(_ctx.item.icon), 3))
                    : _createCommentVNode("", true),
                  _createElementVNode("span", null, _toDisplayString(_ctx.item.title), 1),
                  (_ctx.item.badge)
                    ? (_openBlock(), _createElementBlock("em", _hoisted_2, _toDisplayString(_ctx.item.badge), 1))
                    : _createCommentVNode("", true)
                ], 2)
              ]),
              _: 1
            }, 8, ["to"]))
          : _createCommentVNode("", true)
  ]))
}