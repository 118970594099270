import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5b8f8da7"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["disabled"]
const _hoisted_2 = {
  key: 0,
  class: "material-icons-round"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    class: _normalizeClass(["md-fab", [_ctx.size, _ctx.color, _ctx.icon ? 'icon' : '']]),
    disabled: _ctx.disabled,
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('click')))
  }, [
    (_ctx.icon)
      ? (_openBlock(), _createElementBlock("i", _hoisted_2, _toDisplayString(_ctx.icon), 1))
      : _createCommentVNode("", true)
  ], 10, _hoisted_1))
}