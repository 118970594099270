
import { defineComponent, PropType } from "vue";

export default defineComponent({
  name: "TransitionSlide",
  props: {
    direction: {
      type: String as PropType<"left" | "right">,
      default: "left"
    }
  }
});
