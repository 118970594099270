
import { defineComponent, PropType } from "vue";
import MdButtonMixin from "./MdButtonMixin";

export default defineComponent({
  name: "MdButton",
  mixins: [MdButtonMixin],
  props: {
    variant: {
      type: String as PropType<
        "elevated" | "filled" | "tonal" | "outlined" | "text" | "icon"
      >,
      required: false,
      default: () => {
        return "elevated";
      },
    },
    grey: Boolean,
    color: String as PropType<"white">,
    icon: {
      type: String,
      required: false,
    },
    mode: {
      type: String as PropType<"delete" | "small_icon">,
      default: "default_mode"
    },
    disabled: Boolean,
  },
  computed: {
    iconOnly() {
      return !this.$slots.default && this.icon;
    },
  },
});
