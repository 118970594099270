
import { defineComponent } from "vue";
import UniPicker from "@/components/bot/UniPicker.vue";
import { useStore } from "@/store";
import { IUniPickerCategory } from "@/types";
import { BotStore, RootStore } from "@/store/constants";
import PickerMixin from "@/components/bot/PickerMixin";

export default defineComponent({
  name: "VariablesPicker",
  mixins: [PickerMixin],
  components: {
    UniPicker
  },
  data() {
    return {
      variable: this.modelValue
    }
  },
  setup() {
    return {
      store: useStore()
    };
  },
  watch: {
    modelValue(val: string) {
      this.variable = val;
    },
    variable(val: string) {
      this.$emit("update:modelValue", val);
    }
  },
  computed: {
    variables(): {[key: string]: IUniPickerCategory} {
      return this.store.getters[BotStore.Getters.GET_PICKER_VARIABLES];
    }
  },
  methods: {
    close() {
      this.$emit("close");
      this.store.commit(RootStore.Mutations.CLOSE_VARIABLE_PICKER);
    },
    select(variable) {
      this.$emit("select", variable);
      this.$emit("update:modelValue", variable);
      const picker = this.store.getters[RootStore.Getters.GET_VARIABLE_PICKER];
      if(!picker) return;
      (picker.target as HTMLInputElement).value = variable.value;
      (picker.target as HTMLInputElement).dispatchEvent(new Event("input"));
      this.store.commit(RootStore.Mutations.CLOSE_VARIABLE_PICKER);
    }
  },
});
