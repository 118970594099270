
import { defineComponent } from "vue";
import { MdSidebar, IMenuItem, ISidebarFAB } from "@/components/md/MdSidebar";

export default defineComponent({
  name: "AdminSidebar",
  components: {
    MdSidebar,
  },
  data() {
    const redirectToInviteNewUsers = () => {
      this.$router.push({
        path: "/admin/users",
        query: {
          invite: 1
        }
      });
    }
    return {
      menu: [
        {
          icon: "dashboard",
          title: "Übersicht",
          href: "/admin/dashboard",
        },
        {
          icon: "person",
          title: "Benutzer",
          href: "/admin/users",
        },
        {
          icon: "group",
          title: "Gruppen",
          href: "/admin/groups",
        },
        {
          icon: "memory",
          title: "Chatbots",
          href: "/admin/chatbots",
        },
        {
          icon: "payment",
          title: "Abrechnung",
          href: "/admin/billing",
        },
        // {
        //   icon: "settings",
        //   title: "Einstellungen",
        //   href: "/admin/settings",
        // },
      ] as IMenuItem[],
      fab: {
        title: "Benutzer hinzufügen",
        icon: "person_add",
        onClick: redirectToInviteNewUsers
      } as ISidebarFAB
    }
  },
});
