
import { defineComponent, PropType } from "vue";
import { MdButton } from "@/components/md/MdButton";
import { MdFab, MdFabExtended } from "@/components/md/MdFab";
import { MdNavigationItem, IMenuItem, ISidebarFAB } from ".";
import { useStore } from "@/store";
import { RootStore } from "@/store/constants";
import ChatCaptainLogo from "@/assets/images/chatcaptain-logo.svg";

export default defineComponent({
  components: {
    MdButton,
    MdFabExtended,
    MdFab,
    MdNavigationItem,
  },
  name: "MdNavigationDrawer",
  setup() {
    const store = useStore();
    return { store }
  },
  props: {
    menu: {
      required: true,
      type: Array as PropType<IMenuItem[]>,
    },
    routerName: String,
    fab: Object as PropType<ISidebarFAB>
  },
  emits: ["item-click", "collapse"],
  data() {
    return {
      ChatCaptainLogo,
      rail: false,
      activeMenu: this.$router.currentRoute
    };
  },
  methods: {
    toggleDrawerRail() {
      this.rail = !this.rail;
      this.$emit("collapse", this.rail);
      this.store.commit(RootStore.Mutations.SIDENAV_RAIL, this.rail);
    },
  },
  computed: {

  }
});
